<template>
  <v-sheet
      :color="CONFIG_PARAM_COLOR('styles' , pageStyle , 'bg' )"
      class="d-flex justify-center"
  >

    <v-sheet color="transparent" max-width="800" width="100%" style="padding: 80px 12px">

      <v-sheet color="transparent"  style="width: 100%">

        <v-sheet color="transparent" v-if="header.config.sticky" :height="SM ?  header.config.heightSM : header.config.height"/>

        <router-link :is="editorMode ? 'span' : 'router-link'" :to="businessPublicLink('')" class="noUnderline">
          <h4
              :style="CONFIG_TEXT_CSS(pageStyle , 'h5' )"
              class="d-flex align-center pointer"
          >
            <v-icon class="mr-3" :color="CONFIG_PARAM_COLOR('styles',pageStyle , 'h5')">
              mdi-arrow-left
            </v-icon>
            {{ $t('Back') }}
          </h4>
        </router-link>


        <h4  class="mt-7"
            :style="CONFIG_TEXT_CSS(pageStyle , 'h5' )"
        >
          {{ MONTH_DAY_TIME(entity.date , false) }}
        </h4>
        <h3
            class="mt-7"
            :style="CONFIG_TEXT_CSS(pageStyle , 'h3' )" >
          {{  entity.title  }}
        </h3>

        <ws-text-viewer
            :value="entity.content"
            is-public
            class="pb-6 mt-7"
            avalon
            :avalon-style="pageStyle"
        />


      </v-sheet>
    </v-sheet>

  </v-sheet>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "viewNews",
  props : {
    alias : {
      type : String
    },
    news : {
      type : Object
    }
  },
  metaInfo() {
    if ( !this.IS_ROUTE_CHANGED )  {
      return {}
    }
    return {
      title: this.entity.title || 'Cторінка'  ,
    }
  },
  data() {
    return {
      entity : {}
    }
  },
  computed : {
    ...mapState('avalon' , [
      'header',
      'editorMode'
    ]),

    pageStyle() {
      return 'light_1'
    }
  },
  watch : {
    alias() {
      this.initPage()
    },
    news : {
      handler() {
        this.entity = this.COPY(this.news)
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_BUSINESS_NEWS_PUBLIC']),

    async initPage() {
      if ( this.news ) {
        this.entity = this.COPY(this.news)
        this.$emit('ready')
        return
      }
      this.entity = await this.GET_BUSINESS_NEWS_PUBLIC(this.alias);
      this.$emit('ready')
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>